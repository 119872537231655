<template>
    <div class="q-gutter-sm q-pa-lg">
    	<div class="row">
    		<div class="row full-width">
		        <div class="full-width">
		          	<h4 class="q-ma-none row inline h4 caps">Top Management FILES</h4>
		        </div>
		    </div>
	    </div>
       <br/>
       <q-form @submit="searchDocument" class="q-gutter-md" >
            <q-input outlined v-model="keyword" label="Search Document" lazy-rules :rules="[ val => val && val.length > 0 || 'Please write your keyword!']" @input="checkIfEmpty"/>
       </q-form>
	    <div class="q-pa-md row items-start q-gutter-lg justify-center">
          <q-card class="my-card bg-secondary text-white" v-for="data in policyData" :key="data.id">

            <q-card-section>
             <div class="text-subtitle2"> {{ data.title }}</div>
            </q-card-section>
            <q-separator dark />
            <q-card-actions align="right">


             <q-btn round flat dense @click="expanded = !expanded">
               <i class="fas fa-angle-up" v-show="expanded"></i>
               <i class="fas fa-angle-down" v-show="!expanded"></i>
             </q-btn>
             <q-space />
              <q-btn flat @click="viewFileData(data.filename)">View</q-btn>
            </q-card-actions>
              <div v-show="expanded">
                <q-separator />
                <q-card-section class="text-subtitle2">
                  <div class="text-overline text-white-9">{{ data.title }}</div>
                  <div class="text-caption">
                     {{ data.descriptions }}
                  </div>
                </q-card-section>
              </div>
          </q-card>
		</div>

      <div class="q-pa-lg flex flex-center" v-show="!isFetching">
        <span>Showing  {{ this.policyData.length > 0 ? this.skipNumber + 1 : 0 }} to {{ this.skipNumber + this.pagination.rowsPerPage > this.totalData ? this.totalData :  this.skipNumber + this.pagination.rowsPerPage}} of  {{ this.totalData }} entries</span>
        <q-space />

        <q-btn flat round color="primary" :disable="pagination.page == 1" @click="firstPage"><i class="fas fa-angle-double-left"></i></q-btn>
        <q-btn flat round color="primary" :disable="pagination.page == 1" @click="prevPage"><i class="fas fa-chevron-left"></i></q-btn>
        <span>{{ this.policyData.length > 0 ? this.pagination.page : 0 }} / {{ this.lastPageNumber }}</span>

        <q-btn flat round color="primary" :disable="this.pagination.page === this.lastPageNumber" @click="nextPage"><i class="fas fa-chevron-right"></i></q-btn>
        <q-btn flat round color="primary" :disable="this.pagination.page === this.lastPageNumber" @click="lastPage"><i class="fas fa-angle-double-right"></i></q-btn>
      </div>

	</div>
</template>

<script>
	import axios from 'axios';
	import mixins from '../../mixins';


	export default {


		data() {
			return {
            expanded: false,
            isFetching:false,
				policyData:[],
            totalData:0,
            skipNumber:0,
            lastPageNumber:0,
            keyword:'',
            pagination: {
                page: 1,
                rowsPerPage: 20,
                rowsNumber: 20
            },

			}
		},

		created(){
			this.fetchFromServer(1);
		},

		methods: {

         viewFileData(fileName){
            var filePath = "https://hris.sgccserver02.com/zfiles/"+ fileName;
             window.open(filePath, '_blank');

         },

         searchDocument(){
            this.fetchFromServer(1);
         },

         checkIfEmpty(){
            if(this.keyword == ''){
               this.fetchFromServer(1);
            }
         },

         fetchFromServer(page) {
            const { rowsPerPage, rowsNumber } = this.pagination
            const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
            const startRow = (page - 1) * rowsPerPage
            this.skipNumber = startRow
            this.isFetching = true
             axios.get(this.apiUrl + '/knowledge/files', {
                 headers: { 'Authorization': `Bearer ${this.token}` },
                 params: {
                   start: startRow,
                   countPerPage: fetchCount,
                   department:this.$route.path.substring(1).split('/')[1],
                   filter: this.keyword,
                 }
             }).then((response) => {
                this.lastPageNumber = response.data.totalPaginate
                this.totalData = response.data.count
                this.policyData = response.data.data
                this.isFetching = false
             }).catch((error) => {
                 console.log(error);
             })
        },

        nextPage() {
           this.pagination.page++
           this.fetchFromServer(this.pagination.page)
        },

        prevPage() {
           if(this.pagination.page > 1){
             this.pagination.page--
            }
            this.fetchFromServer(this.pagination.page)
        },

        firstPage() {
            if(this.pagination.page > 1){
             this.pagination.page = 1
            }
            this.fetchFromServer(this.pagination.page)
        },

        lastPage() {
           this.pagination.page = this.lastPageNumber
           this.fetchFromServer(this.pagination.page)
        }
		}
	};

</script>
<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 350px
</style>
